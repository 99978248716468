import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import {
  DEFAULT_INSPECTION_CATEGORY,
  DEFAULT_INSPECTION_TYPE,
  DEFAULT_PRIORITY_TYPES,
  INSPECTION_CATEGORIES,
  INSPECTION_TYPES,
  type IWorkAssignment,
  PRIORITY_TYPES,
  WORK_ASSIGNMENT_ENDPOINT,
} from "../../shared/models/IWorkAssignment"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../shared/models/IAccount"
import SelectFilteredSingle from "../../shared/components/SelectFilteredSingle"
import { CUSTOMER_ENDPOINT, type ICustomer } from "../../shared/models/ICustomer"
import { type ILocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import AddIcon from "@mui/icons-material/Add"
import EqualsIcon from "@mui/icons-material/DoubleArrow"
import { dateAsIso, valueOrUndefined } from "../../shared/utilities/format_utility"
import { useLocation } from "@reach/router"
import FormatNumberAndError from "../../shared/components/format/FormatNumberAndError"
import FormatNumber from "../../shared/components/format/FormatNumber"
import useApiAdd, { type IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"
import FhMuiTextField from "../../shared/components/form/FhMuiTextField"
import FhMuiSelectField from "../../shared/components/form/FhMuiSelectField"
import FhMuiMdField from "../../shared/components/form/FhMuiMdField"
import { WORK_ASSIGNMENT_VIEW_URL } from "../../config/urls"
import FhMuiDateField from "../../shared/components/form/FhMuiDateField"
import moment from "moment"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../shared/models/IReportFormat"
import { Update as UpdateIcon } from "@mui/icons-material"
import CustomerForm from "../customers/components/CustomerForm"
import AccountForm from "../accounts/components/AccountForm"
import LocationForm from "../locations/components/LocationForm"
import SavedWAs from "./components/SavedWAs"
import FhMuiCheckboxField from "../../shared/components/form/FhMuiCheckboxField"
import { type IListItem } from "../../shared/models/component/IListItem"

const customerListRepository = new RestRepository<IListItem>(CUSTOMER_ENDPOINT)
const customerAddRepository = new RestRepository<ICustomer>(CUSTOMER_ENDPOINT)

const accountListRepository = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)
const accountAddRepository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)

const locationListRepository = new RestRepository<IListItem>(LOCATION_ENDPOINT)
const locationAddRepository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)
const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

const redirectView = WORK_ASSIGNMENT_VIEW_URL

interface ILocationState {
  customer: ICustomer
  account: IAccount
  location: ILocation
}

/**
 * The add page for work assignments.
 *
 * todo Notes format is not getting correctly saved. Pasting from outlook all the formatting is getting removed.
 *
 * @returns {React.FunctionComponent} the add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const { control, handleSubmit, setError, formState, setValue, getValues, reset, clearErrors } = useForm()
  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [account, setAccount] = useState<IAccount | null>(null)
  const [location, setLocation] = useState<ILocation | null>(null)
  const [reportFormat, setReportFormat] = useState<IReportFormat | null>(null)
  const [savedWAs, setSavedWAs] = useState<IWorkAssignment[]>([])

  const [hourlyRate, setHourlyRate] = useState(false)

  const [showAddCustomerForm, setShowAddCustomerForm] = useState<boolean>(false)
  const [showAddAccountForm, setShowAddAccountForm] = useState<boolean>(false)
  const [showAddLocationForm, setShowAddLocationForm] = useState<boolean>(false)

  const browserLocation = useLocation()

  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  const props: IUseApiAddProps<IWorkAssignment> = {
    apiFunction: repository.add,
    setError,
    redirectView,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IWorkAssignment>(props)

  const handleSave = useCallback(
    async (wa: IWorkAssignment) => {
      const waForDates: IWorkAssignment = {
        ...wa,
        rli_getting_paid: valueOrUndefined(wa.rli_getting_paid) as number,
        due_date: dateAsIso(wa.due_date as moment.Moment),
        issued_date: dateAsIso(wa.issued_date as moment.Moment),
        visit_date: dateAsIso(wa.visit_date as moment.Moment),
        consultant_due_date: dateAsIso(wa.consultant_due_date as moment.Moment),
      }
      const saveAndAddAnother = Boolean(getValues("save_and_add_another"))

      const newWa = await handleAdd(waForDates, saveAndAddAnother)
      if (newWa !== undefined) {
        const wa2 = await repository.read(newWa.id)
        setSavedWAs(prevWAs => [...prevWAs, wa2])
        clearErrors()
        reset()
        setValue("save_and_add_another", saveAndAddAnother)
        setCustomer(null)
        setAccount(null)
        setLocation(null)
      }
    },
    [customer],
  )

  const handleTiv = useCallback(() => {
    const propertyDamage = Number(getValues("property_damage"))
    const businessInterruption = Number(getValues("business_interruption"))
    setValue("total_insured_value", propertyDamage + businessInterruption)
  }, [])

  // CUSTOMER
  const {
    control: controlCustomer,
    handleSubmit: handleSubmitCustomer,
    setValue: setValueCustomer,
    clearErrors: clearErrorsCustomer,
    reset: resetCustomer,
  } = useForm()
  const propsCustomer: IUseApiAddProps<ICustomer> = {
    apiFunction: customerAddRepository.add,
    setError,
    redirect: false,
  }
  const {
    saving: savingCustomer,
    handleAdd: handleAddCustomer,
    connectionError: connectionErrorCustomer,
  } = useApiAdd<ICustomer>(propsCustomer)

  // noinspection DuplicatedCode
  const handleCustomer = useCallback((customer1?: IListItem | null) => {
    setValue("customer", customer1 === null || customer1 === undefined ? null : customer1.id)
    setCustomer(customer1 !== undefined ? (customer1 as ICustomer) : null)

    setValue("account", null)
    setAccount(null)

    setValue("location", null)
    setLocation(null)
  }, [])

  const handleCustomerAdd = useCallback(
    async (customer1: ICustomer) => {
      const customer2 = await handleAddCustomer(customer1)
      if (customer2 !== undefined) {
        handleCustomer(customer2)
        resetCustomer()
        clearErrorsCustomer()
        setShowAddCustomerForm(false)
      }
    },
    [handleCustomer],
  )
  // END CUSTOMER

  // ACCOUNT
  const {
    control: controlAccount,
    handleSubmit: handleSubmitAccount,
    setValue: setValueAccount,
    clearErrors: clearErrorsAccount,
    reset: resetAccount,
  } = useForm()
  const propsAccount: IUseApiAddProps<IAccount> = {
    apiFunction: accountAddRepository.add,
    setError,
    redirect: false,
  }
  const { saving: savingAccount, handleAdd: handleAddAccount, connectionError: connectionErrorAccount } = useApiAdd<IAccount>(propsAccount)

  // noinspection DuplicatedCode
  const handleAccount = useCallback((account1?: IListItem | null) => {
    setValue("account", account1 === null || account1 === undefined ? null : account1.id)
    setAccount(account1 !== undefined ? (account1 as IAccount) : null)

    setValue("location", null)
    setLocation(null)
  }, [])

  const handleAccountAdd = useCallback(
    async (account1: IAccount) => {
      if (customer !== null) {
        account1.customer = customer.id
        const account2 = await handleAddAccount(account1)
        if (account2 !== undefined) {
          handleAccount(account2)
          clearErrorsAccount()
          resetAccount()
          setShowAddAccountForm(false)
        }
      }
    },
    [customer],
  )
  // END ACCOUNT

  // LOCATION
  const {
    control: controlLocation,
    handleSubmit: handleSubmitLocation,
    setValue: setValueLocation,
    clearErrors: clearErrorsLocation,
    getValues: getValuesLocation,
    reset: resetLocation,
  } = useForm()
  const propsLocation: IUseApiAddProps<ILocation> = {
    apiFunction: locationAddRepository.add,
    setError,
    redirect: false,
  }
  const {
    saving: savingLocation,
    handleAdd: handleAddLocation,
    connectionError: connectionErrorLocation,
  } = useApiAdd<ILocation>(propsLocation)

  // noinspection DuplicatedCode
  const handleLocation = useCallback((location1?: IListItem | null) => {
    if (location1 !== undefined && location1 !== null) {
      setValue("property_damage", (location1 as ILocation).property_damage)
      setValue("business_interruption", (location1 as ILocation).business_interruption)
      setValue("total_insured_value", (location1 as ILocation).total_insured_value)
      setValue("location", location1.id)
      setLocation(location1 as ILocation)
    } else {
      setValue("property_damage", 0)
      setValue("business_interruption", 0)
      setValue("total_insured_value", 0)
      setValue("location", null)
      setLocation(null)
    }
  }, [])

  const handleLocationAdd = useCallback(
    async (location1: ILocation) => {
      if (account !== null) {
        location1.accounts = [account.id]
        location1.business_interruption = location1.business_interruption ?? 0
        location1.property_damage = location1.property_damage ?? 0
        location1.total_insured_value = location1.total_insured_value ?? 0

        const location2 = await handleAddLocation(location1)
        if (location2 !== undefined) {
          location2.name = location2.name !== null && location2.name !== "" ? location2.name : location2.address
          handleLocation(location2)
          resetLocation()
          clearErrorsLocation()
          setShowAddLocationForm(false)
        }
      }
    },
    [account],
  )

  const handleLocationSelected = useCallback((location2: ILocation) => {
    location2.name = location2.name !== null && location2.name !== "" ? location2.name : location2.address
    handleLocation(location2)
    resetLocation()
    clearErrorsLocation()
    setShowAddLocationForm(false)
  }, [])
  // END LOCATION

  const handleInspectionCategoryChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === INSPECTION_CATEGORIES[2].key) {
      const addedToWF: moment.Moment = getValues("issued_date")
      setValue("consultant_due_date", addedToWF.clone().add(7, "d"))
    }
  }, [])

  // noinspection DuplicatedCode
  const handleReportFormat = useCallback((reportFormat1?: IListItem | null) => {
    setValue("report_format", reportFormat1 === null || reportFormat1 === undefined ? null : reportFormat1.id)
    setReportFormat(reportFormat1 !== undefined ? (reportFormat1 as IReportFormat) : null)
  }, [])

  const handleUpdateSuggestedFee = useCallback(() => {
    const rliGettingPaid = Number(getValues("rli_getting_paid"))
    setValue("suggested_consultant_amount", rliGettingPaid / 2)
  }, [])

  const handleHourlyRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHourlyRate(event.target.checked)
  }, [])

  useEffect(() => {
    if (browserLocation?.state !== undefined && browserLocation.state !== null) {
      const { customer, account, location } = browserLocation.state as ILocationState
      if (customer !== null && customer !== undefined) {
        handleCustomer(customer)
      }
      if (account !== null && account !== undefined) {
        handleAccount(account)
      }
      if (location !== null && location !== undefined) {
        const locationNew = {
          ...location,
          name: location.name !== "" ? location.name : location.address,
        }
        handleLocation(locationNew)
      }
    }
  }, [browserLocation.state])

  return (
    <WorkflowPage>
      <Box
        sx={{
          ...styles.page,
          p: isSmall ? 0 : 2,
        }}
      >
        <HeaderPanel title="Add WA" loading={saving || savingCustomer || savingAccount || savingLocation} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Grid container>
          <Grid item xs={12} md={6}>
            <SavedWAs was={savedWAs} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            p: isSmall ? 0 : 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} md={6}>
            <Grid container alignItems="center" sx={{ mt: 2 }}>
              {!showAddCustomerForm && (
                <>
                  <Grid item xs>
                    <SelectFilteredSingle
                      name="customer"
                      label="Customer"
                      defaultValue={customer as IListItem}
                      repository={customerListRepository}
                      onChange={handleCustomer}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setShowAddCustomerForm(true)}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </>
              )}

              {showAddCustomerForm && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Paper>
                    <Typography
                      component="h4"
                      sx={{
                        p: 2,
                        pb: 0,
                      }}
                    >
                      Add Customer
                    </Typography>
                    <ErrorMessage error={connectionErrorCustomer} />
                    <Box
                      component="form"
                      onSubmit={handleSubmitCustomer(handleCustomerAdd as SubmitHandler<FieldValues>)}
                      autoComplete="off"
                      sx={{ p: 2 }}
                    >
                      <CustomerForm control={controlCustomer} minimal setValue={setValueCustomer} clearErrors={clearErrorsCustomer} />
                      <FormActionPanel onCancel={() => setShowAddCustomerForm(false)} />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {!showAddAccountForm && customer !== null && (
              <Grid container sx={{ mt: 2 }} alignItems="center">
                <Grid item xs>
                  <SelectFilteredSingle
                    name="account"
                    label="Account"
                    defaultValue={account as IListItem}
                    filters={[
                      {
                        field: "customer",
                        value: customer?.id,
                      },
                    ]}
                    repository={accountListRepository}
                    onChange={handleAccount}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setShowAddAccountForm(true)}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {showAddAccountForm && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Paper>
                  <Typography
                    component="h4"
                    sx={{
                      p: 2,
                      pb: 0,
                    }}
                  >
                    Add Account
                  </Typography>
                  <ErrorMessage error={connectionErrorAccount} />
                  <Box
                    component="form"
                    onSubmit={handleSubmitAccount(handleAccountAdd as SubmitHandler<FieldValues>)}
                    autoComplete="off"
                    sx={{ p: 2 }}
                  >
                    <AccountForm control={controlAccount} setValue={setValueAccount} minimal clearErrors={clearErrorsAccount} />
                    <FormActionPanel onCancel={() => setShowAddAccountForm(false)} />
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {!showAddLocationForm && account !== null && (
              <Grid container sx={{ mt: 2 }} alignItems="center">
                <Grid item xs>
                  <SelectFilteredSingle
                    name="location"
                    label="Location"
                    defaultValue={location as IListItem}
                    filters={[
                      {
                        field: "accounts",
                        value: account?.id,
                      },
                    ]}
                    repository={locationListRepository}
                    onChange={handleLocation}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setShowAddLocationForm(true)}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {showAddLocationForm && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Paper>
                  <Typography
                    component="h4"
                    sx={{
                      p: 2,
                      pb: 0,
                    }}
                  >
                    Add Location
                  </Typography>
                  <ErrorMessage error={connectionErrorLocation} />
                  <Box
                    component="form"
                    onSubmit={handleSubmitLocation(handleLocationAdd as SubmitHandler<FieldValues>)}
                    autoComplete="off"
                    sx={{ p: 2 }}
                  >
                    <LocationForm
                      control={controlLocation}
                      setValue={setValueLocation}
                      getValues={getValuesLocation}
                      minimal
                      clearErrors={clearErrorsLocation}
                      parentAccount={account}
                      onLocationSelected={handleLocationSelected}
                    />
                    <FormActionPanel onCancel={() => setShowAddLocationForm(false)} />
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off">
          <Grid
            container
            spacing={2}
            sx={{
              p: isSmall ? 0 : 2,
              pb: 2,
            }}
          >
            <Grid item xs={12} md={6}>
              {location !== null && (
                <>
                  <Grid container alignItems="end" sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <strong>Location</strong>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="end">
                    <Grid item xs={4}>
                      <small>
                        <em>Property Damage</em>
                      </small>
                      <Box
                        sx={{
                          pb: 1,
                          pr: 1,
                        }}
                      >
                        <FormatNumber value={location?.property_damage} />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <small>
                        <em>Business Interruption</em>
                      </small>
                      <Box
                        sx={{
                          pb: 1,
                          pr: 1,
                        }}
                      >
                        <FormatNumber value={location?.business_interruption} />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <small>
                        <em>Total Insurable Value</em>
                      </small>
                      <Box
                        sx={{
                          pb: 1,
                          pr: 1,
                        }}
                      >
                        <FormatNumber value={location?.total_insured_value} />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="end">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <Box component="strong">Work Assignment</Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="end">
                    <Grid item xs={5} md={3}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="Property Damage"
                        name="property_damage"
                        defaultValue={0}
                        formatter={FormatNumberAndError}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={1}
                      sx={{
                        textAlign: "center",
                        mb: 4,
                      }}
                    >
                      <AddIcon />
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="Business Interruption"
                        name="business_interruption"
                        defaultValue={0}
                        formatter={FormatNumberAndError}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={1}
                      sx={{
                        textAlign: "center",
                        mb: 4,
                      }}
                    >
                      <IconButton color="primary" component="span" onClick={handleTiv}>
                        <EqualsIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} md={3}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="Total Insurable Value"
                        name="total_insured_value"
                        defaultValue={0}
                        formatter={FormatNumberAndError}
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FhMuiSelectField
                        control={control}
                        items={INSPECTION_CATEGORIES}
                        defaultValue={DEFAULT_INSPECTION_CATEGORY.key}
                        name="inspection_category"
                        label="Inspection Category"
                        onChange={handleInspectionCategoryChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FhMuiSelectField
                        control={control}
                        items={INSPECTION_TYPES}
                        defaultValue={DEFAULT_INSPECTION_TYPE.key}
                        name="inspection_type"
                        label="Inspection Type"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectFilteredSingle
                        name="report_format"
                        label="Report Format"
                        defaultValue={reportFormat as IListItem}
                        repository={reportFormatRepository}
                        onChange={handleReportFormat}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FhMuiSelectField
                        control={control}
                        items={PRIORITY_TYPES}
                        defaultValue={DEFAULT_PRIORITY_TYPES.key}
                        name="priority"
                        label="Priority"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FhMuiTextField control={control} label="External Tracking" name="external_tracking" />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            {location !== null && (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FhMuiCheckboxField
                      control={control}
                      defaultValue={false}
                      onChange={handleHourlyRate}
                      name="rli_getting_paid_hourly_rate"
                      label="Hourly Rate"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiTextField
                      control={control}
                      type="text"
                      defaultValue={0}
                      label="RLI Getting Paid"
                      name="rli_getting_paid"
                      suffixUnits={hourlyRate ? "/hr" : ""}
                      formatter={FormatNumberAndError}
                    />
                  </Grid>
                  <Grid item xs>
                    <FhMuiTextField
                      control={control}
                      type="text"
                      defaultValue={0}
                      label="Suggested Consultant Fee"
                      name="suggested_consultant_amount"
                      formatter={FormatNumberAndError}
                    />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <IconButton onClick={handleUpdateSuggestedFee}>
                        <UpdateIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <FhMuiDateField control={control} defaultValue={moment()} label="Added to WF" name="issued_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiDateField control={control} label="Visit Date" name="visit_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiDateField control={control} label="Consultant Due Date" name="consultant_due_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiDateField control={control} label="Due Date" name="due_date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiTextField
                      control={control}
                      type="number"
                      defaultValue={0}
                      label="Due Date Days After Visit"
                      name="due_date_days_after_visit"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FhMuiTextField
                      control={control}
                      type="number"
                      defaultValue={0}
                      label="Consultant Due Date Days After Visit"
                      name="consultant_due_date_days_after_visit"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiCheckboxField
                      control={control}
                      label="Allow Overwrite of Consultant Due Date"
                      name="allow_overwrite_of_consultant_due_date"
                      defaultValue={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isSmall ? (
                      <Box sx={{ mt: 2 }}>
                        <FhMuiTextField control={control} multiline={true} label="Internal Notes" name="notes" />
                      </Box>
                    ) : (
                      <FhMuiMdField control={control} name="notes" label="Internal Notes" />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isSmall ? (
                      <Box sx={{ mt: 2 }}>
                        <FhMuiTextField control={control} multiline={true} label="Consultant Notes" name="notes_consultant" />
                      </Box>
                    ) : (
                      <FhMuiMdField control={control} name="notes_consultant" label="Consultant Notes" />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isSmall ? (
                      <Box sx={{ mt: 2 }}>
                        <FhMuiTextField control={control} multiline={true} label="Contact Notes" name="contact_notes" />
                      </Box>
                    ) : (
                      <FhMuiMdField control={control} name="contact_notes" label="Contact Notes" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <FormActionPanel saveAndAdd={true} control={control} disabled={account === null || customer === null || location === null} />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
