import * as React from "react"
import { useCallback, useState } from "react"
import { type IWorkAssignment, type Progress, WORK_ASSIGNMENT_ENDPOINT, workAssignmentSteps } from "../models/IWorkAssignment"
import { Button, CircularProgress, Grid, LinearProgress, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import Moment from "react-moment"
import { DATE_FORMAT } from "../../config/config"
import SignOffButton from "./SignOffButton"
import { RestRepository } from "../repositories/RestRepository"
import { type IPaging } from "../models/IPaging"
import { styles } from "../styling/general"
import ClearIcon from "@mui/icons-material/Clear"
import { CONNECTION_ERROR, type IConnectionError } from "../models/IConnectionError"
import ErrorMessage from "./ErrorMessage"
import { getDateFromDateTimeString } from "../utilities/format_utility"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  workAssignment: IWorkAssignment
  progress: Progress
  onChange: (workAssignment: IWorkAssignment) => void
  handleRefreshScore: () => void
}

/**
 * Displays the progress of a work assignment and the ability to sign off on each step.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the progress table.
 */
const WorkAssignmentProgressTable: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { workAssignment: wa, progress, onChange, handleRefreshScore } = props
  const [loading, setLoading] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleSignOff = useCallback(async (field: string, date?: string) => {
    setLoading(true)
    setSavingError(null)
    try {
      const paging: IPaging = {
        filters: [
          {
            field: "field",
            value: field,
          },
        ],
      }
      if (date !== undefined) {
        paging.filters?.push({
          field: "date",
          value: date,
        })
      }
      const wa1 = await waRepository.action(wa.id, "sign_off", paging)

      if (field === "progress_report_sent_account") {
        handleRefreshScore()
      }

      onChange(wa1)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response as IConnectionError)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [wa, handleRefreshScore])

  const handleClear = useCallback(
    (field: string) => async () => {
      setLoading(true)
      setSavingError(null)
      try {
        const paging: IPaging = {
          filters: [
            {
              field: "field",
              value: field,
            },
          ],
        }
        const wa1 = await waRepository.action(wa.id, "sign_off_clear", paging)

        if (field === "progress_report_sent_account") {
          handleRefreshScore()
        }

        onChange(wa1)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    },
    [wa, handleRefreshScore]
  )

  return (
    <Grid container spacing={2} sx={styles.viewProperty} alignItems="bottom">
      <Grid item xs>
        <Typography fontWeight={600}>Status</Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={400}>
          <em>{progress.getCurrentName()}</em>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ErrorMessage error={savingError} />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <LinearProgress variant={"determinate"} value={progress.getProgress()} sx={styles.progress} />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="text.secondary">
              {loading ? <CircularProgress size={18} /> : progress.getPercent()}
            </Typography>
          </Grid>
        </Grid>
        <Table size="small">
          <TableBody>
            {workAssignmentSteps.map((step, index) => (
              <TableRow key={index} selected={step.field === progress.getField()}>
                <TableCell
                  sx={{
                    textAlign: "right",
                    pl: 0,
                    pr: 0,
                  }}
                >
                  {step.step}.
                </TableCell>
                <TableCell>{step.title}</TableCell>
                <TableCell>
                  {(wa as any)[step.field] !== null ? (
                    <Moment format={DATE_FORMAT}>{getDateFromDateTimeString((wa as any)[step.field] as string)}</Moment>
                  ) : (
                    <SignOffButton disabled={loading} onClick={handleSignOff} field={step.field} />
                  )}
                </TableCell>
                <TableCell>
                  {(wa as any)[step.field] !== null && (
                    <>
                      <Button size="small" startIcon={<ClearIcon />} onClick={handleClear(step.field)}>
                        {(wa as any)[step.field + "_by"]?.name !== undefined ? (wa as any)[step.field + "_by"]?.name : "Completed"}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default WorkAssignmentProgressTable
