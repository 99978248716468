import * as React from "react"
import { useMemo } from "react"
import { Avatar, Chip } from "@mui/material"
import { styles } from "../../styling/general"
import { getRatingText } from "../../models/main/IConsultant"

interface IProps {
  rating: number | null
}

/**
 * Displays the rating for the consultant in a visual way.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant rating.
 */
const ConsultantRating: React.FunctionComponent<IProps> = (props: IProps) => {
  const { rating } = props

  const roundedRating = rating !== null ? Math.round(rating * 10)/10 : null

  const ratingText = useMemo(() => getRatingText(roundedRating), [roundedRating])

  if (rating === 0 || rating === null) {
    return <></>
  }

  return (
    <Chip
      size="medium"
      variant="filled"
      avatar={<Avatar sx={styles.rating.avatar(roundedRating)}>{roundedRating}</Avatar>}
      label={ratingText}
    />
  )
}

export default ConsultantRating
