import { type IConsultantLocation } from "../IConsultantLocation"
import { type ILanguage } from "../ILanguage"
import { type IConsultantSpecialty } from "../IConsultantSpecialty"
import { type IStorageFile } from "../IStorageFile"
import { type IMainModel } from "../IMainModel"
import type IProfile from "./IProfile"
import { green, yellow, red } from "@mui/material/colors"

export const CONSULTANTS_ENDPOINT = "consultants"
export const CONSULTANTS_AND_SPECIALTIES_ENDPOINT = "consultants_and_specialties"

export const PHONE_TYPES = [
  {
    key: "landline",
    value: "Landline",
  },
  {
    key: "iphone",
    value: "iPhone",
  },
  {
    key: "android",
    value: "Android",
  },
  {
    key: "whatsapp",
    value: "WhatsApp",
  },
]

export const DEFAULT_PHONE_TYPE = PHONE_TYPES[1]

export const PREFERRED_COM_TYPES = [
  {
    key: "email",
    value: "Email",
  },
  {
    key: "phone",
    value: "Phone",
  },
  {
    key: "both",
    value: "Both",
  },
]

export const DEFAULT_PREFERRED_COM_TYPE = PREFERRED_COM_TYPES[1]
export const PREFERRED_PAYMENT_TYPES = [
  {
    key: "ach",
    value: "ACH",
  },
  {
    key: "wire",
    value: "Wire",
  },
  {
    key: "adp",
    value: "ADP",
  },
]

export const DEFAULT_PREFERRED_PAYMENT_TYPES = PREFERRED_PAYMENT_TYPES[1]

/**
 * Determines the rating text based on the given rating range.
 * @param {number} rating The rating value.
 * @returns {string} The corresponding rating label.
 */
export const getRatingText = (rating: number | null): string => {
  if (rating === null) return "No Rating"
  if (rating >= 4.5) return "Excellent"
  if (rating >= 3.75) return "Good"
  if (rating >= 3) return "Fair"
  if (rating >= 2) return "Poor"
  return "Very Poor"
}

/**
 * Determines the specialty score color based on the rating range.
 * @param {number | null} score - The consultant's score.
 * @returns {string} - The corresponding color.
 */
export const getScoreColor = (score: number | null): string => {
  if (score === null) return "transparent"
  if (score >= 4.5) return green[400]      // Excellent
  if (score >= 3.75) return green[400]       // Good
  if (score >= 3) return yellow[400]     // Fair
  if (score >= 2) return red[400]     // Poor
  return red[400]                        // Very Poor
}

/**
 * Check to see if an object is a consultant.
 *
 * @param {any} obj to check.
 * @returns {boolean} true if it is.
 */
export const isConsultant = (obj: any): obj is IConsultant => true

/**
 * Get the consultant's phone number.
 *
 * @param {IConsultant} consultant the consultant.
 * @returns {string} the phone number with the extension.
 */
export const getConsultantPhone = (consultant: IConsultant): string => {
  const ext = consultant.phone_ext !== "" && consultant.phone_ext !== null ? `x${consultant.phone_ext}` : ""
  return `${consultant.phone}${ext}`
}

/**
 * Get the consultant's second phone number.
 *
 * @param {IConsultant} consultant the consultant.
 * @returns {string} the second phone number with the extension.
 */
export const getConsultantPhone2 = (consultant: IConsultant): string => {
  const ext = consultant.phone_2_ext !== "" && consultant.phone_2_ext !== null ? `x${consultant.phone_2_ext}` : ""
  return `${consultant.phone_2}${ext}`
}

/**
 * Give a consultant get a location.
 *
 * @param {IConsultant | any} consultant to get the location for.
 * @param {boolean} alt is this the alternative location?
 * @returns {IConsultantLocation | null} the consultant location or null
 */
export const consultantLocation = (consultant: IConsultant | any, alt: boolean = false): IConsultantLocation | null => {
  if (isConsultant(consultant)) {
    return {
      latitude: consultant[`latitude${alt ? "_alt" : ""}`],
      longitude: consultant[`longitude${alt ? "_alt" : ""}`],
      radius: consultant[`radius${alt ? "_alt" : ""}`] as number,
      comment: `
          <div>${consultant[`address${alt ? "_alt" : ""}`]}</div>
          <div>${consultant[`address_2${alt ? "_alt" : ""}`]}</div>
          <div>
            ${consultant[`city${alt ? "_alt" : ""}`]}, ${consultant[`state_region${alt ? "_alt" : ""}`]}
            ${consultant[`postal_code${alt ? "_alt" : ""}`]} ${consultant[`country${alt ? "_alt" : ""}`]}
          </div>
        `,
    }
  }
  return null
}

export interface IConsultant extends IMainModel {
  id: number
  identifier: string
  first_name: string
  last_name: string
  legal_entity_name: string | null

  highly_protected_risk: boolean
  property_lite: boolean
  highly_protected_risk_lite: boolean
  boiler_and_machinery: boolean
  casualty: boolean
  tech_reviewer: boolean
  admin_reviewer: boolean
  plan_reviewer: boolean
  plan_reviewer_hourly: boolean
  plan_reviewer_rate: number

  name: string
  active: boolean
  send_weekly_reminder_email: boolean
  imported: boolean
  email: string
  email_2: string
  phone: string
  phone_ext: string
  phone_type: string
  phone_2: string
  phone_2_ext: string
  phone_2_type: string
  preferred_com: "phone" | "email"
  expense_report_payment_type: "ach" | "wire"

  address: string
  address_2: string
  city: string
  state_region: string
  postal_code: string
  country: string

  longitude: number
  latitude: number
  radius: number

  address_alt: string
  address_2_alt: string
  city_alt: string
  state_region_alt: string
  country_alt: string
  postal_code_alt: number
  longitude_alt: number
  latitude_alt: number
  radius_alt: string

  will_travel_regions?: string[]
  rating: number

  languages: ILanguage[]

  specialties: IConsultantSpecialty[]
  specialty_count: number
  active_work_assignments_count: number

  start_of_property_experience: number
  start_of_hpr_experience: number
  start_of_casualty_experience: number

  other_experience: string

  notes: string
  notes_rendered: string

  contract_date: string | null
  sign_date: string | null
  resume_on_file: string
  rli_resume_on_file: string
  business_cards_received: string | null

  profile_image: IStorageFile
  resume: IStorageFile
  profile: IProfile
  last_login: string | null

  created: string
  updated: string
}
