import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Box, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import {
  DEFAULT_INSPECTION_CATEGORY,
  DEFAULT_INSPECTION_TYPE,
  DEFAULT_PRIORITY_TYPES,
  INSPECTION_CATEGORIES,
  INSPECTION_TYPES,
  type IWorkAssignment,
  PRIORITY_TYPES,
  WORK_ASSIGNMENT_ENDPOINT,
} from "../../shared/models/IWorkAssignment"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../shared/models/IAccount"
import SelectFilteredSingle from "../../shared/components/SelectFilteredSingle"
import { CUSTOMER_ENDPOINT, type ICustomer } from "../../shared/models/ICustomer"
import { type ILocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import WorkAssignmentMap from "../../shared/components/WorkAssignmentMap"
import AddIcon from "@mui/icons-material/Add"
import EqualsIcon from "@mui/icons-material/DoubleArrow"
import { dateAsIso, getDatePlus, isoAsDate, valueOrUndefined } from "../../shared/utilities/format_utility"
import FormatNumberAndError from "../../shared/components/format/FormatNumberAndError"
import FormatNumber from "../../shared/components/format/FormatNumber"
import WorkAssignmentLocation from "../../shared/components/WorkAssignmentLocation"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import { useApiRead } from "../../shared/hooks/useApiRead"
import FhMuiIdField from "../../shared/components/form/FhMuiIdField"
import FhMuiSelectField from "../../shared/components/form/FhMuiSelectField"
import FhMuiTextField from "../../shared/components/form/FhMuiTextField"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiEdit, { type IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import FhMuiDateField from "../../shared/components/form/FhMuiDateField"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../shared/models/IReportFormat"
import { Update as UpdateIcon } from "@mui/icons-material"
import FhMuiCheckboxField from "../../shared/components/form/FhMuiCheckboxField"
import { type IListItem } from "../../shared/models/component/IListItem"
import type moment from "moment"

const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const workAssignmentRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)
const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)

/**
 * The edit page for work assignments.
 *
 * @returns {React.FC} the edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const { control, handleSubmit, setError, formState, setValue, getValues } = useForm()
  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [account, setAccount] = useState<IAccount | null>()
  const [location, setLocation] = useState<ILocation | null>()
  const [reportFormat, setReportFormat] = useState<IReportFormat | null>()
  const { tab, handleTabChange } = useTabPanel()

  const [hourlyRate, setHourlyRate] = useState(false)

  const [wa, setWa] = useState<IWorkAssignment | null>(null)
  const { data, loading, error } = useApiRead<IWorkAssignment>({ apiFunction: workAssignmentRepository.read })

  const props: IUseApiEditProps<IWorkAssignment> = {
    apiFunction: workAssignmentRepository.edit,
    setError,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<IWorkAssignment>(props)

  const handleSave = useCallback(
    async (wa1: IWorkAssignment) => {
      const waForDates: IWorkAssignment = {
        ...wa1,
        rli_getting_paid: valueOrUndefined(wa1.rli_getting_paid) as number,
        due_date: dateAsIso(wa1.due_date as moment.Moment),
        issued_date: dateAsIso(wa1.issued_date as moment.Moment),
        visit_date: dateAsIso(wa1.visit_date as moment.Moment),
        consultant_due_date: dateAsIso(wa1.consultant_due_date as moment.Moment),
      }
      await handleEdit(waForDates)
    },
    [wa]
  )

  // noinspection DuplicatedCode
  const handleTiv = useCallback(() => {
    const propertyDamage = Number(getValues("property_damage"))
    const businessInterruption = Number(getValues("business_interruption"))
    setValue("total_insured_value", propertyDamage + businessInterruption)
  }, [])

  // noinspection DuplicatedCode
  const handleCustomer = useCallback((customer1?: IListItem | null) => {
    setValue("customer", customer1 === null || customer1 === undefined ? null : customer1.id)
    setCustomer(customer1 !== undefined ? (customer1 as ICustomer) : null)

    setValue("account", null)
    setAccount(null)

    setValue("location", null)
    setLocation(null)
  }, [])

  // noinspection DuplicatedCode
  const handleAccount = useCallback((account1?: IListItem | null) => {
    setValue("account", account1 === null || account1 === undefined ? null : account1.id)
    setAccount(account1 !== undefined ? (account1 as IAccount) : null)

    setValue("location", null)
    setLocation(null)
  }, [])

  // noinspection DuplicatedCode
  const handleLocation = useCallback((location1?: IListItem | null) => {
    if (location1 !== undefined && location1 !== null) {
      setValue("property_damage", (location1 as ILocation).property_damage)
      setValue("business_interruption", (location1 as ILocation).business_interruption)
      setValue("total_insured_value", (location1 as ILocation).total_insured_value)
      setValue("location", location1.id)
      setLocation(location1 as ILocation)
    } else {
      setValue("property_damage", 0)
      setValue("business_interruption", 0)
      setValue("total_insured_value", 0)
      setValue("location", null)
      setLocation(null)
    }
  }, [])

  // noinspection DuplicatedCode
  const handleReportFormat = useCallback((reportFormat1?: IListItem | null) => {
    setValue("report_format", reportFormat1 === null || reportFormat1 === undefined ? null : reportFormat1.id)
    setReportFormat(reportFormat1 !== undefined ? (reportFormat1 as IReportFormat) : null)
  }, [])

  const handleUpdateSuggestedFee = useCallback(() => {
    const rliGettingPaid = Number(getValues("rli_getting_paid"))
    setValue("suggested_consultant_amount", rliGettingPaid / 2)
  }, [])

  const handleHourlyRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHourlyRate(event.target.checked)
  }, [])

  useEffect(() => {
    if (data !== undefined && !loading && wa == null) {
      // Since location name is optional, update the name with the address so something shows in the list.
      if (data.location?.name === "" || data.location?.name === null) {
        data.location.name = data.location.address
      }
      setWa(data)
      handleCustomer(data.customer as ICustomer)
      handleAccount(data.account as IAccount)
      handleLocation(data.location)
      handleReportFormat(data.report_format as IReportFormat)
      setValue("id", data.id)
      setValue("inspection_category", data.inspection_category)
      setValue("inspection_type", data.inspection_type)
      setValue("priority", data.priority)
      setValue("property_damage", data.property_damage)
      setValue("business_interruption", data.business_interruption)
      setValue("total_insured_value", data.total_insured_value)
      setValue("external_tracking", data.external_tracking)
      setValue("rli_getting_paid", data.rli_getting_paid)
      setValue("rli_getting_paid_hourly_rate", data.rli_getting_paid_hourly_rate)
      setHourlyRate(data.rli_getting_paid_hourly_rate)
      setValue("work_assignment_approved", data.work_assignment_approved)
      setValue("suggested_consultant_amount", data.suggested_consultant_amount)
      setValue("due_date_days_after_visit", data.due_date_days_after_visit)
      setValue("consultant_due_date_days_after_visit", data.consultant_due_date_days_after_visit)
      setValue("allow_overwrite_of_consultant_due_date", data.allow_overwrite_of_consultant_due_date)

      setValue("issued_date", isoAsDate(data.issued_date as string))
      setValue("visit_date", isoAsDate(data.visit_date as string))
      setValue("due_date", isoAsDate(data.due_date as string))
      setValue("consultant_due_date", isoAsDate(data.consultant_due_date as string))
    }
  }, [data, setValue, loading])

  const title = useMemo(() => {
    if (wa != null) {
      return `${wa?.identifier} | Edit Work Assignment`
    }
    return "Edit Work Assignment"
  }, [wa])

  // noinspection DuplicatedCode
  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title={title} loading={loading || saving} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off">
          <Box sx={styles.tabsBox}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="Overview" />
              <Tab label="Details" />
            </Tabs>
          </Box>

          <Grid container spacing={2} sx={{ p: 2 }}>
            <FhMuiIdField control={control} />
            <Grid item xs={12} md={5}>
              <TabPanel index={0} value={tab}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="customer"
                      label="Customer"
                      defaultValue={customer as IListItem}
                      repository={customerRepository}
                      onChange={handleCustomer}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {customer != null && (
                      <SelectFilteredSingle
                        name="account"
                        label="Account"
                        defaultValue={account as IListItem}
                        filters={[
                          {
                            field: "customer",
                            value: customer.id,
                          },
                        ]}
                        repository={accountRepository}
                        onChange={handleAccount}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {account != null && (
                      <SelectFilteredSingle
                        name="location"
                        label="Location"
                        defaultValue={location as IListItem}
                        filters={[
                          {
                            field: "accounts",
                            value: account.id,
                          },
                        ]}
                        repository={locationRepository}
                        onChange={handleLocation}
                      />
                    )}
                  </Grid>
                  {location != null && (
                    <>
                      <Grid item xs={6}>
                        <FhMuiSelectField
                          control={control}
                          items={INSPECTION_CATEGORIES}
                          defaultValue={DEFAULT_INSPECTION_CATEGORY.key}
                          name="inspection_category"
                          label="Inspection Category"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FhMuiSelectField
                          control={control}
                          items={INSPECTION_TYPES}
                          defaultValue={DEFAULT_INSPECTION_TYPE.key}
                          name="inspection_type"
                          label="Inspection Type"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectFilteredSingle
                          name="report_format"
                          label="Report Format"
                          defaultValue={reportFormat as IListItem}
                          repository={reportFormatRepository}
                          onChange={handleReportFormat}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FhMuiSelectField
                          control={control}
                          items={PRIORITY_TYPES}
                          defaultValue={DEFAULT_PRIORITY_TYPES.key}
                          name="priority"
                          label="Priority"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FhMuiTextField control={control} label="External Tracking" name="external_tracking" />
                      </Grid>
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel index={1} value={tab}>
                {location != null && (
                  <>
                    <Grid container alignItems="end" spacing={2}>
                      <Grid item xs={12}>
                        <strong>Location</strong>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="end" spacing={2}>
                      <Grid item xs={4}>
                        <small>
                          <em>Property Damage</em>
                        </small>
                        <Box
                          sx={{
                            pb: 1,
                            pr: 1,
                          }}
                        >
                          <FormatNumber value={location?.property_damage} />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <small>
                          <em>Business Interruption</em>
                        </small>
                        <Box
                          sx={{
                            pb: 1,
                            pr: 1,
                          }}
                        >
                          <FormatNumber value={location?.business_interruption} />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <small>
                          <em>Total Insurable Value</em>
                        </small>
                        <Box
                          sx={{
                            pb: 1,
                            pr: 1,
                          }}
                        >
                          <FormatNumber value={location?.total_insured_value} />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="end" spacing={2}>
                      <Grid item xs={12} sx={{ pb: 2 }}>
                        <Box component="strong">Work Assignment</Box>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="end" spacing={2}>
                      <Grid item xs={3}>
                        <FhMuiTextField
                          control={control}
                          type="number"
                          label="Property Damage"
                          name="property_damage"
                          formatter={FormatNumberAndError}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          textAlign: "center",
                          mb: 4,
                        }}
                      >
                        <AddIcon />
                      </Grid>
                      <Grid item xs={3}>
                        <FhMuiTextField
                          control={control}
                          type="number"
                          label="Business Interruption"
                          name="business_interruption"
                          formatter={FormatNumberAndError}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          textAlign: "center",
                          mb: 4,
                        }}
                      >
                        <IconButton color="primary" component="span" onClick={handleTiv}>
                          <EqualsIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <FhMuiTextField
                          control={control}
                          type="number"
                          label="Total Insurable Value"
                          name="total_insured_value"
                          formatter={FormatNumberAndError}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
                  <Grid item xs={6}>
                    <FhMuiCheckboxField
                      control={control}
                      onChange={handleHourlyRate}
                      name="rli_getting_paid_hourly_rate"
                      label="Hourly Rate"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiCheckboxField control={control} name="work_assignment_approved" label="Approved" />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiTextField
                      control={control}
                      type="text"
                      label="RLI Getting Paid"
                      name="rli_getting_paid"
                      suffixUnits={hourlyRate ? "/hr" : ""}
                      formatter={FormatNumberAndError}
                    />
                  </Grid>
                  <Grid item xs>
                    <FhMuiTextField
                      control={control}
                      type="text"
                      label="Suggested Consultant Fee"
                      name="suggested_consultant_amount"
                      formatter={FormatNumberAndError}
                    />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <IconButton onClick={handleUpdateSuggestedFee}>
                        <UpdateIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <FhMuiDateField control={control} defaultValue={getDatePlus()} label="Added to WF" name="issued_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiDateField control={control} label="Visit Date" name="visit_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiDateField control={control} label="Consultant Due Date" name="consultant_due_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiDateField control={control} label="Due Date" name="due_date" />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiTextField
                      control={control}
                      type="number"
                      label="Consultant Due Date Days After Visit"
                      name="consultant_due_date_days_after_visit"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FhMuiTextField control={control} type="number" label="Due Date Days After Visit" name="due_date_days_after_visit" />
                  </Grid>
                  <Grid item xs={12}>
                    <Alert color="info">All calculated due dates are base on business days and Risk Logic holidays.</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiCheckboxField control={control} label="Allow Overwrite of Consultant Due Date" name="allow_overwrite_of_consultant_due_date" />
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item xs={12} md={7}>
              <>
                <Grid container spacing={4} sx={{ mb: 2 }}>
                  <Grid item>
                    <Typography variant="h5" component="h5">
                      {customer?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" component="h6">
                      {account?.name}
                    </Typography>
                  </Grid>
                </Grid>
                {location != null && (
                  <>
                    <WorkAssignmentLocation location={location} />
                    <WorkAssignmentMap location={location} />
                  </>
                )}
              </>
            </Grid>
          </Grid>
          <FormActionPanel disabled={customer == null || account == null || location == null} />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
