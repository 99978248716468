import { type IWorkAssignment } from "./IWorkAssignment"
import { type IConsultant } from "./main/IConsultant"

export const WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "work_assignment_consultants"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "expense_report_work_assignment_consultants"
export const CONSULTANTS_WORK_ASSIGNMENT_ENDPOINT = "consultants_work_assignment"

export interface IWorkAssignmentConsultant {
  id: number
  amount: number
  total_amount: number
  accepted: boolean
  declined: boolean
  request_sent: boolean
  hourly_rate: boolean
  hours: number
  consultant_comment: string
  message: string
  priority: number
  created: string
  updated: string
  work_assignment: IWorkAssignment | number
  consultant: IConsultant
  scoring_admin_review_comment: string
  scoring_tech_review_comment: string
  scoring_tech_2_review_comment: string
  scoring_formatting_quality: number
  scoring_technical_quality: number
  scoring_responsiveness: number
  scoring_average: number
}

/**
 * Checks if the "Admin" review type is completed.
 * Completion is defined by all scoring fields and the admin review comment being filled.
 *
 * @param {IWorkAssignmentConsultant | undefined} wac - The work assignment consultant object to check.
 * @returns {boolean} - Returns true if all "Admin" scoring criteria are completed; otherwise, false.
 */
export const isAdminScoringComplete = (wac?: IWorkAssignmentConsultant):boolean => {
  return (
    wac !== undefined &&
    wac !== null &&
    wac.scoring_formatting_quality > 0 &&
    wac.scoring_admin_review_comment?.length > 0
  )
}

/**
 * Checks if the "Tech" review type is completed.
 * Completion is defined by all scoring fields and the tech review comment being filled.
 *
 * @param {IWorkAssignmentConsultant | undefined} wac - The work assignment consultant object to check.
 * @returns {boolean} - Returns true if all "Tech" scoring criteria are completed; otherwise, false.
 */
export const isTechScoringComplete = (wac?: IWorkAssignmentConsultant):boolean => {
  return (
    wac !== undefined &&
    wac !== null &&
    wac.scoring_technical_quality > 0 &&
    wac.scoring_tech_review_comment?.length > 0
  )
}

/**
 * Checks if the "Tech 2" review type is completed.
 * Completion is defined by all scoring fields and the tech 2 review comment being filled.
 *
 * @param {IWorkAssignmentConsultant | undefined} wac - The work assignment consultant object to check.
 * @returns {boolean} - Returns true if all "Tech 2" scoring criteria are completed; otherwise, false.
 */
export const isTech2ScoringComplete = (wac?: IWorkAssignmentConsultant):boolean => {
  return (
    wac !== undefined &&
    wac !== null &&
    wac.scoring_technical_quality > 0 &&
    wac.scoring_tech_2_review_comment?.length > 0
  )
}
